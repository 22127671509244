import React from "react";
import { Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import Button from '@material-ui/core/Button';

import CardTitle from './CardTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from "@material-ui/core/Link";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import MapChart from "./MapChart";

import MessageDialog from "./MessageDialog.js";
import ShowMoreDialog from "./ShowMoreDialog.js";
import TimeSelector from './TimeSelector';
import IndexValueCard from "./IndexValueCard.js";
import ProjectGraph from "./ProjectGraph.js";
import BrushChart from "./BrushChart";
import Message from "./Message";
import TopicDeck from "./TopicDeck";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ReclassifyDialog from "./ReclassifyDialog";
import EditTopicDialog from "./EditTopicDialog";
import TopUserList from "./TopUserList";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    statisticsTable: {
	color: '#666',
	margin: 0,
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    total: {
	backgroundColor: '#f0f0f0',
    },
    container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4)
    },
    score: {
	marginLeft: '0.25em',
    },
    chart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
    },
    standardChart: {
	boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
	minHeight: '22em',
    },
    statusContainer: {
    },
    statusInnerContainer: {
        textAlign: 'center',
        width: '100%'
    }
});

class CubeView extends React.Component {
    constructor(props) {
	super(props);
	
	this.state = {
	    more_popular_users: false,
	    more_active_users: false,
	    data: null,
	    filteredData: null,
	    hashtagDialogOpen: false,
	    reclassify: false,
	    historyLevel: 0,
	};
	this.default_texts = {
	    'map_caption': 'Activity by Region',
	    'gender_caption': 'Activity by User Type',
	    'device_caption': 'Activity by Device',
	    'province_caption': 'Activity by Province',
	    'major_region_caption': 'Activity by Major Region',
	    'platform_caption': 'Activity by Source',
	};
	this.insecurity_texts = {
	    'map_caption': 'Turvattomuus alueittain',
	    'gender_caption': 'Turvattomuus sukupuolittain',
	    'device_caption': 'Turvattomuus laitetyypeittäin',
	    'province_caption': 'Turvattomuus maakunnittain',
	    'major_region_caption': 'Turvattomuus suuralueittain',
	    'platform_caption': 'Turvattomuus alustoittain',
	};
	this.major_regions = {
	    '246/1': { name: 'Helsinki-Uusimaa' },
	    '246/2': { name: 'Etelä-Suomi' },
	    '246/3': { name: 'Länsi-Suomi' },
	    '246/4': { name: 'Pohjois- ja Itä-Suomi' },
	    '246/5': { name: 'Ahvenanmaa' },
	};
	this.emotionLabels = [ 'Neutral', 'Anger', 'Anticipation', 'Disgust', 'Fear', 'Joy', 'Sadness', 'Surprise (neg)', 'Trust', 'Surprise (pos)' ];
	this.orderedEmotionLabels = [ 'Anger', 'Disgust', 'Fear', 'Sadness', 'Surprise (neg)', 'Anticipation', 'Joy', 'Trust', 'Surprise (pos)' ];
	this.emotionOrder = [ 1, 3, 4, 6, 7, 2, 5, 8, 9 ];
	// this.tagged_labels = [ 'Untagged', 'Tagged' ];
	this.genderLabels = [ 'Tuntematon', 'Mies', 'Nainen', 'Automatisoitu', 'Instituutio' ];
	this.datasetTypeLabels = [ 'Unknown', 'Media', 'Organizational', 'Personal Blog', 'Forums', 'Public Sector', 'Social', 'Comments', 'Podcast' ];
	this.deviceLabels = [ 'Unknown', 'Apple', 'Android', 'Windows Phone' ];
	this.sentimentLabels = [ 'Neutral', 'Positive', 'Negative' ];
	this.sentimentColors = [ '#aebdcd', '#10B3EB', '#F05560' ];
    }

    updateFilter(key, value) {
	this.props.onUpdateFilter(key, value);	
    }

    isFiltered(filter) {
	for (let [key, value] of Object.entries(filter)) {
	    if (key !== "v" && key !== "tv") return true;
	}
	return false;
    }

    isFilteredSlow(filter) {
	for (let [key, value] of Object.entries(filter)) {
	    if (key !== "v" && key !== "tv" && key !== "t") return true;
	}
	return false;
    }

    componentDidMount() {
	const props = this.props;
	// const is_filtered = this.isFiltered(props.filter);

	this.getFastData();
	this.getData();
	this.getData(props.filter);
    }
    componentWillUnmount() {
	// clearTimeout(this.intervalId);
    }
    reloadAll() {
	const props = this.props;
	// const is_filtered = this.isFiltered(props.filter);
	
	this.setState({ isLoaded: false, significants: null });
	this.getFastData();
	this.getData();
	this.getData(props.filter);
    }
    componentDidUpdate(prevProps) {
	const props = this.props;
	let reload_all = false, reload = false, clear = false;
	const is_filtered = this.isFiltered(props.filter);
	if (props.project && (!prevProps.project || props.project.id !== prevProps.project.id)) {
	    reload_all = reload = true;
	} else if (props.filter.v !== prevProps.filter.v) {
	    if (!is_filtered) {
	     	clear = true;
	    } else {
	     	reload = true;
	    }	   
	}

	if (reload_all) {
	    this.reloadAll();
	} else if (reload) {
	    this.getData(props.filter);
	} else if (clear) {
	    this.setState({ actualVersion: props.filter.v, significants: null });
	    props.onHideBackdrop();
	}
    }

    getUsers(filter) {
	const { project } = this.props;
	if (!project) return;
	
	const showTagsAndUsers = project.id !== 31 && project.id !== 13 && project.id !== 51 && project.id !== 56 && project.id !== 58;
	const is_filtered = this.isFiltered(filter);
	
	let p = { ...filter, id: project.id, mode: 'users' };

	if (showTagsAndUsers) {
	    fetch("https://api.sometrik.com/project/cube?" + new URLSearchParams(p), { credentials: 'include' } )
		.then(res => res.json())
		.then(
		    (result) => {
			this.setState({
			    users: result.users,
			});
		    },
		    // Note: it's important to handle errors here
		    // instead of a catch() block so that we don't swallow
		    // exceptions from actual bugs in components.
		    (error) => {
		    }
		)
	}
    }

    getGraph(filter) {
	const { project } = this.props;
	if (!project) return;
	
	let p = { ...filter, id: project.id, mode: 'graph' };

	console.log("loading graph");

	this.setState({ graph: null });
	
	fetch("https://api.sometrik.com/project/cube?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			graph: result.graph,
		    });
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    )
    }

    getMessages(filter, visible_topics) {
	const { project } = this.props;
	if (!project) return;
	
	const p = { ...filter, id: project.id, mode: 'messages', topics: Object.keys(visible_topics).join(',') };
	const is_filtered = this.isFiltered(filter);

	fetch("https://api.sometrik.com/project/cube?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			messages: result.messages,
		    });
		    this.getHashtags(filter);
		    this.getUsers(filter);
		    this.getGraph(filter);
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    )	    
    }
    
    getHashtags(filter) {
	const { project } = this.props;
	if (!project) return;
	
	const p = { ...filter, id: project.id, mode: 'tags' };
	const is_filtered = this.isFiltered(filter);
	
	fetch("https://api.sometrik.com/project/cube?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			hashtags: result.hashtags,
		    });
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    )	    
    }

    calculatePropertyStats(type, t1, t2, total1, total2, target) {
	if (t1 && t2) {
	    for (let [key, v1a] of Object.entries(t1)) {
		const v1 = 100 * v1a / total1;
		const v2 = 100 * t2[key] / total2;
		const diff = v1 - v2;
		if (Math.abs(diff) > 0.1) target.push({ type: type, key: type + '=' + key, selected: v1, base: v2, diff: diff });
	    }
	}
    }

    calculatePropertyStatsArray(type, t1, t2, total1, total2, target) {
	if (t1 && t2) {	    
	    for (let g = 1; g < t1.length; g++) {
		const v1 = 100 * t1[g] / total1;
		const v2 = 100 * t2[g] / total2;
		const diff = v1 - v2;
		if (Math.abs(diff) > 0.1) target.push({ type: 'type', key: type + '=' + g, selected: v1, base: v2, diff: diff });
	    }
	}
    }
	
    calculateTopProperties(data, filteredData) {
	if (!data || !filteredData) {
	    return null;
	}

	const { project } = this.props;

	const show_topics = project.topic_collection_id ? true : false;
	const show_emotions = project.use_emotions ? true : false;

	let topData = [ ], topKeys = [ ], allData = [ ];
	
    	const total1 = filteredData.total_activity, total2 = data.total_activity;
	let properties = [ ];
	
	if (data.datasets && filteredData.datasets) {
	    this.calculatePropertyStats('dataset', filteredData.datasets.data, data.datasets.data, total1, total2, properties);
	}
	
	if (show_topics && data.topics && filteredData.topics) {
	    this.calculatePropertyStats('topic', filteredData.topics.data, data.topics.data, total1, total2, properties);
	}
	
	if (show_emotions) {
	    this.calculatePropertyStatsArray('emotion', filteredData.emotion_dist, data.emotion_dist, total1, total2, properties);
	}
	
	this.calculatePropertyStatsArray('gender', filteredData.gender_dist, data.gender_dist, total1, total2, properties);
	this.calculatePropertyStatsArray('device', filteredData.device_dist, data.device_dist, total1, total2, properties);
	this.calculatePropertyStatsArray('dataset_type', filteredData.dataset_type, data.dataset_type, total1, total2, properties);
	
	properties.sort((a, b) => b.diff - a.diff);
	
	let values1 = [ ], values2 = [ ];
	for (let j = 0; j < properties.length; j++) {
	    const v = properties[j];
	    allData.push({ type: v.type, key: v.key, value: v.selected - v.base });

	    if (j < 3 || j >= properties.length - 3) {
		values1.push(v.selected);
		values2.push(v.base);
		topKeys.push(v.key);
	    }
	}
	
	topData = [ { name: 'Filtered', data: values1 }, { name: 'Unfiltered', data: values2 } ];	  
	
	return {
	    top: topData,
	    topKeys: topKeys,
	    all: allData,	   
	};
    }

    getFastData() {
	const { project } = this.props;
	if (!project) return;
	
	let p = { id: project.id, mode: 'activity' };

	fetch("https://api.sometrik.com/project/cube?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    this.setState({
			fastData: result,
		    });
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    )	
    }
    
    // getData(filter) {

    // 	const is_time_filtered = filter.gender == null && filter.dataset == null && filter.dataset_type == null && filter.device == null && filter.country == null && filter.region == null && filter.province == null && filter.topic == null && filter.sentiment == null;
    // 	const is_filtered = this.isFiltered(filter);

    // 	if (is_time_filtered) {
    // 	} else {
    // 	    this.getBasicData(filter);
    // 	}
    // }

    getData(filter) {
	const { project } = this.props;
	if (!project) return;
	
	if (filter == null) filter = { };
	const is_filtered = this.isFiltered(filter);
	let p = { ...filter, id: project.id };

	fetch("https://api.sometrik.com/project/cube?" + new URLSearchParams(p), { credentials: 'include' } )
	    .then(res => res.json())
	    .then(
		(result) => {
		    let significants = null;
		    if (is_filtered) {
			if (this.state.data) {
			    significants = this.calculateTopProperties(this.state.data, result);
			}
			this.setState({
			    filteredData: result,
			    isLoaded: true,
			    actualVersion: filter.v,
			    significants: significants,
			});
		    } else {
			if (this.state.filteredData) {
			    significants = this.calculateTopProperties(result, this.state.filteredData);
			}
			this.setState({
			    data: result,
			    isLoaded: true,
			    actualVersion: filter.v,
			    significants: significants,
			});
		    }
		    
		    this.props.onHideBackdrop();

		    if (is_filtered) {
			let visible_topics = { };
			['most_active','most_emotional','trending'].forEach( type => {
			    result.topics[type].slice(0, 9).forEach( topic => {
				visible_topics[topic.id] = 1;
			    });			
			});
			
			this.getMessages(filter, visible_topics);
		    }
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
		}
	    )
    }

    render() {
	console.log("rendering cube");
	
	const { projectId, project, classes, filter, staticData, filterNames, onUpdateFilter } = this.props;
	const { error, data, filteredData, fastData, users, hashtags, more_active_users, more_popular_users, messages, isLoaded } = this.state;

	const user = this.props.app.getUser.bind(this.props.app)();
	const isRelative = project.parent_project_id != null;
	const base_url = "/project/" + projectId;

	const is_filtered = this.isFiltered(filter);
	const is_filtered_slow = this.isFilteredSlow(filter);

	const unfilteredData = data != null ? data : { };
	
	const activeData = is_filtered && filteredData ? filteredData : data;
	
	const active_categories = staticData.topics;
	
	let avgFear = 0.5;

	const show_topics = project.topic_collection_id ? true : false;
	const show_emotions = project.use_emotions ? true : false;
	const show_devices = false; // projectId !== 31;
	const show_countries = false; // projectId !== 31;
	const show_index = false; // projectId === 31;
	const texts = projectId === 31 ? this.insecurity_texts : this.default_texts;
	const is_static = project.is_static;

	const rewindHistory = (path) => {
	    const level = this.state.historyLevel;
	    if (level >= 1) {
		this.props.history.goBack();
		this.setState({ historyLevel: level - 1 });
	    } else {
		this.props.history.replace(path);
	    }
	};
	const moveToPage = (path) => {
	    this.props.history.push(path);
	    this.setState({ historyLevel: this.state.historyLevel + 1 });
	};
	const getDatasetName = (id) => {
	    const ds = staticData.datasets && staticData.datasets[id];
	    if (ds) return ds.name;
	    else return '#' + id;
	}

	const getCountryName = (id) => {
	    const c = staticData.countries && staticData.countries[id];
	    if (c) return c.name;
	    else return '#' + id;
	}

	const getProvinceName = (id) => {
	    const r = staticData.provinces && staticData.provinces[id];
	    if (r) return r.name;
	    else return '#' + id;
	}

	const handleTimeSelection = (t) => {
	    this.updateFilter( 't', t );
	}
	const clearFixedTimeSelection = () => {
	    this.props.onSelectFixedTime();
	}
	const onSelectFixedTime = (n, from, to) => {
	    this.props.onSelectFixedTime(n, from, to);
	}

	const basicFormatterAbsolute = value => (typeof value === 'number') ? value.toFixed(0) : value;
	const bias = 2;
	
	const basicFormatter = isRelative ?
	      value => (typeof value === 'number') ? value.toFixed(2) + ' %' : value
	      : basicFormatterAbsolute;
	const basicFormatterBias = isRelative ?
	      value => (typeof value === 'number') ? (value - bias).toFixed(2) + ' %' : value
	      : basicFormatterAbsolute;

	const basicFormatterVeryThin = isRelative ?
	      value => (typeof value === 'number') ? value.toFixed(0) : value
	      : basicFormatterAbsolute;
	const basicFormatterThin = isRelative ?
	      value => (typeof value === 'number') ? value.toFixed(1) : value
	      : basicFormatterAbsolute;
	
	const basicFormatterRel = value => (typeof value === 'number') ? value.toFixed(0) + '%' : value;
	const basicFormatterRelBias = value => (typeof value === 'number') ? (value - bias).toFixed(0) + '%' : value;
	const basicFormatterDigits = value => (typeof value === 'number') ? value.toFixed(1) + '%' : value;

	const brushChartColors = [ '#798897', '#e69c24' ];
	
	let activityData = [ ];
	let brushData = [ ];
	let genderData = [ ];
	let datasetTypeData = [ ];
	let sentimentData = [ ];
	let deviceData = [ ];
	let emotionData = [ ];
	let topTopicData = [ ], emotionalTopicData = [ ], trendingTopicData = [ ];
	let seriesHourBars = [ ];
	let seriesDowBars = [ ];
	let platformData = [ ];
	let countryData = [ ];
	let topCountriesData = [ ];
	let topMajorRegionsData = [ ];
	let topProvincesData = [ ];
	let majorRegionData = [ ];
	let provinceData = [ ];
	let regionData = [ ];
	let countryTopics = { };
	let majorRegionTopics = { };
	let provinceTopics = { };
	let regionTopics = { };
	let totalPlatforms = 0;
	let totalCountries = 0;
	// let taggedPostsData = [ ];

	let timeseries = null;
	if (!is_filtered_slow && fastData) timeseries = fastData.timeseries;
	if (!timeseries && activeData) timeseries = activeData.timeseries;
	if (timeseries) {
	    const time = timeseries.t;
	    const activity = timeseries.activity;
	    // const smoothed_activity = timeseries.smoothed_activity;
	    // const new_users = timeseries.new_users;

	    activityData.push({
		name: 'Activity',
		data: time.map( (t, i) => [ t, activity[i] ] ),
	    });
	    // activityData.push({
	    //  	name: 'Activity (smoothed)',
	    //  	data: time.map( (t, i) => [ t, smoothed_activity[i] ] ).filter( (v, i) => i % 3 === 0 ),
	    // });
	}

	let unfiltered_timeseries = null;
	if (!is_filtered_slow && fastData) unfiltered_timeseries = fastData.timeseries;
	if (!unfiltered_timeseries && data) unfiltered_timeseries = data.timeseries;
	
	if (unfiltered_timeseries) {
	    const time = unfiltered_timeseries.t;
	    const activity = unfiltered_timeseries.activity;
	    // const smoothed_activity = unfiltered_timeseries.smoothed_activity;

	    brushData.push({
	     	name: 'Activity',
		data: time.map( (t, i) => [ t, activity[i] ] ),
	     	// data: time.map( (t, i) => [ t, smoothed_activity[i] ] ).filter( (v, i) => i % 3 === 0 ),
	    });
	}

	let ownUserData = [ 0, 0 ];
	
	if (activeData) {
	    avgFear = activeData.index_value;
	    	    
	    if (activeData.time_dist) {
		seriesHourBars = [ {
		    name: "Aikajakauma",
                    data: activeData.time_dist,
		} ];
	    }
	    
	    if (activeData.dow_dist) {
		seriesDowBars = [ {
		    name: "Viikonpäiväjakauma",
                    data: activeData.dow_dist,
		} ];
	    }

	    if (activeData.own_user_dist) {
		ownUserData = activeData.own_user_dist;
	    }
	    
	    if (activeData.gender_dist) {
		let d = activeData.gender_dist;
		if (isRelative) {
		    genderData = [ { name: 'Sukupuoli', data: d } ];
		} else {
		    genderData = d;
		}
	    }

	    if (activeData.dataset_type_dist) {
		datasetTypeData = [ { name: 'Source Type', data: activeData.dataset_type_dist } ];
	    }

	    if (activeData.sentiment_dist) {
		sentimentData = activeData.sentiment_dist
	    }

	    if (activeData.device_dist) {
		let d = activeData.device_dist;
		if (isRelative) {
		    deviceData = [ { name: 'Device', data: d } ];
		} else {
		    deviceData = d;
		}
	    }
	    
	    if (activeData.emotion_dist) {
		emotionData = [ { name: 'Emotion', data: this.emotionOrder.map( i => activeData.emotion_dist[i] ) } ];
	    }

	    if (activeData.datasets) {
		platformData = activeData.datasets.most_active;
		totalPlatforms = activeData.datasets.n;
	    }

	    if (activeData.countries) {
		topCountriesData = activeData.countries.most_active;
		countryData = activeData.countries.data;
		totalCountries = activeData.countries.n;
		countryTopics = activeData && activeData.countries.topics;
	    }
	    if (activeData.major_regions) {
		topMajorRegionsData = activeData.major_regions.most_active;
		majorRegionData = activeData.major_regions.data;
		majorRegionTopics = activeData && activeData.major_regions.topics;
	    }
	    if (activeData.provinces) {
		topProvincesData = activeData.provinces.most_active;
		provinceData = activeData.provinces.data;
		provinceTopics = activeData && activeData.provinces.topics;
	    }
	    if (activeData.regions) {
		regionData = activeData.regions.data;
		regionTopics = activeData.regions.topics;
	    }
	    if (activeData.topics) {
		topTopicData = activeData.topics.most_active;
		emotionalTopicData = activeData.topics.most_emotional;
		trendingTopicData = activeData.topics.trending;
	    }
	    // if (activeData.tagged_dist) {
	    // 	taggedPostsData = activeData.tagged_dist;
	    // }
	}

	const chartStates = {
	    states: {
		active: {
		    filter: {
			type: 'none'
		    }
		},
		hover: {
		    filter: {
			type: 'lighten'
		    }
		}
	    }
	};

	const topFearOptions = {
	    grid: {
		show: false,
	    },
            yaxis: {
                labels: {
		    show: false,
                    formatter: basicFormatterDigits,
                },
            },
            dataLabels: {
                // enabled: false,
                formatter: basicFormatterDigits,
		style: {
		    colors: [ '#333' ],
		}
            },
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.props.onUpdateFilter('education', config.selectedDataPoints[0].map( v => v + 1 ));
			// this.props.onUpdateFilter('education', config.dataPointIndex + 1);
		    },
		},
		animations: {
                    enabled: false
		},
	    },
            xaxis: {
		labels: {
		    show: true,
                },
		categories: this.state.significants && this.state.significants.topKeys.map( key => filterNames[key] || key ),
            },
	    legend: {
		position: 'top',
	    },
	    stroke: {
		show: true,
		width: 1,
		colors: ['#fff']
            },
	    colors: [ '#d73027', '#c0c0c0'],
	    ...chartStates
        };

	const topTopicMapping = topTopicData.map( t => t.id );
	const emotionalTopicMapping = emotionalTopicData.map( t => t.id );
	const trendingTopicMapping = trendingTopicData.map( t => t.id );
	
	let hashtagData = [ ];
	let countryHashtagData = { };
	let provinceHashtagData = { };
	let majorRegionHashtagData = { };
	let regionHashtagData = { };
	let totalHashtags = 0;
	if (hashtags) {
	    hashtagData = hashtags.most_used;
	    countryHashtagData = hashtags.by_country;
	    majorRegionHashtagData = hashtags.by_major_region;
	    provinceHashtagData = hashtags.by_province;
	    regionHashtagData = hashtags.by_region;
	    totalHashtags = hashtags.n;
	}

	let activeUserData = [ ];
	let popularUserData = [ ];
	if (users) {
	    activeUserData = users.most_active;
	    popularUserData = users.most_popular;
	}

	const show_topics_on_map = false;
	const layers = [
	    {
		countries: staticData.countries,
		hashtags: show_topics_on_map ? countryTopics : countryHashtagData,
		data: countryData,
		src: "/world-110m.json",
		maxZoom: 0.5,
		onClick: (id) => {
		    onUpdateFilter('country', id);
		},
		solo: filter.country != null,
	    },
	    {
		hashtags: show_topics_on_map ? majorRegionTopics : majorRegionHashtagData,
		countries: this.major_regions,
		data: majorRegionData,
		src: '/nuts2.json',
		minZoom: 0.5,
		maxZoom: 0.85,
		onClick: (key) => {
		    onUpdateFilter('major_region', key);
		},
		solo: filter.major_region != null,
	    },
	    {
		hashtags: show_topics_on_map ? provinceTopics : provinceHashtagData,
		countries: staticData.provinces,
		data: provinceData,
		src: '/nuts3.json',
		minZoom: 0.85,
		maxZoom: 2.6,
		onClick: (key) => {
		    onUpdateFilter('province', key);
		},
		solo: filter.province != null,
	    },
	    {
		hashtags: show_topics_on_map ? regionTopics : regionHashtagData,
		countries: staticData.regions,
		data: regionData,
		src: "/Kunnat2020_topojson.json",
		minZoom: 2.6,
		onClick: (id) => {
		    onUpdateFilter('region', id);
		},
		solo: filter.region != null,
	    },
	];
	
	const toggleMorePopularUsers = () => {
	    this.setState( (state) => ({ more_popular_users: !state.more_popular_users }) );
	}

	const toggleMoreActiveUsers = () => {
	    this.setState( (state) => ({ more_active_users: !state.more_active_users }) );
	}

	const showFollowers = projectId == 1012;
	const showUsers = projectId !== 31 && projectId !== 13 && projectId !== 51 && projectId !== 56 && projectId !== 58;
	const showTags = true;

	const binWidth = 0.125;
	const plotColorRange = [
	    {
		from: avgFear - 100,
		to: avgFear - 2.5 * binWidth,
		color: '#4575b4'
	    }, {
		from: avgFear - 2.5 * binWidth,
		to: avgFear - 1.5 * binWidth,
		color: '#91bfdb'
	    }, {
		from: avgFear - 1.5 * binWidth,
		to: avgFear - binWidth / 2,
		color: '#e0f3f8'
	    }, {
		from: avgFear - binWidth / 2,
		to: avgFear + binWidth / 2,
		color: '#c0c0c0'
	    }, {
		from: avgFear + binWidth / 2,
		to: avgFear + 1.5 * binWidth,
		color: '#fee090'
	    }, {
		from: avgFear + 1.5 * binWidth,
		to: avgFear + 2.5 * binWidth,
		color: '#fc8d59'
	    }, {
		from: avgFear + 2.5 * binWidth,
		to: avgFear + 100,
		color: '#d73027'
	    }
	];

	const barOptions = {
	    grid: {
		show: false,
	    },
            yaxis: {
                labels: {
		    show: false,
                    formatter: basicFormatter
                },
            },
            dataLabels: {
                // enabled: false,
                formatter: basicFormatterThin,
		style: {
		    colors: [ '#333' ],
		}
            },
	    chart: {
	    	animations: {
		    enabled: false
		},
	    },
	    plotOptions: {
                bar: {
                    colors: {
			ranges: plotColorRange,
                    },
                    columnWidth: '80%',
                }
            },
	    ...chartStates,
	};
	const optionsTopTopicBars = {
	    grid: {
		show: false,
	    },
            yaxis: {
                labels: {
		    show: false,
                    formatter: basicFormatterDigits
                },
            },
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.updateFilter('topic', topTopicMapping[config.dataPointIndex] );
		    }
		},
		animations: {
		    enabled: false
		},
	    },
            xaxis: {
                categories: topTopicData.map( t => (active_categories && active_categories[t.id]) || '#' + t.id ),
            },
	    dataLabels: {
		enabled: true,
		style: {
		    colors: ['#333']
		},
		formatter: basicFormatterDigits,
	    },
	    colors: [ '#798897' ],
	    ...chartStates
        };	
	const optionsEmotionalTopicBars = {
	    ...optionsTopTopicBars,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.updateFilter('topic', emotionalTopicMapping[config.dataPointIndex] );
		    }
		},
		animations: {
		    enabled: false
		},
	    },
            xaxis: {
                categories: emotionalTopicData.map( t => (active_categories && active_categories[t.id]) || '#' + t.id ),
            },
	    colors: [ '#FF4C45' ],
	    ...chartStates
        };	
	const optionsTrendingTopicBars = {
	    ...optionsTopTopicBars,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.updateFilter('topic', trendingTopicMapping[config.dataPointIndex] );
		    }
		},
		animations: {
		    enabled: false
		},
	    },
            xaxis: {
                categories: trendingTopicData.map( t => (active_categories && active_categories[t.id]) || '#' + t.id ),
            },
	    ...chartStates
        };	
	const optionsEmotionBars = {
	    ...barOptions,
	    legend: {
		show: false,
	    },
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.updateFilter('emotion', this.emotionOrder[config.dataPointIndex] );
		    }
		},
		animations: {
		    enabled: false
		},
	    },
            xaxis: {
		categories: this.orderedEmotionLabels,
            },
	    plotOptions: {
		bar: {
		    distributed: true
		}
	    },
	    colors: [ '#F05560', '#F05560', '#F05560', '#F05560', '#F05560', '#10B3EB', '#10B3EB', '#10B3EB' ],
	    ...chartStates
        };	

	const optionsHourBars = {
	    ...barOptions,
            xaxis: {
                categories: [ '00-03', '03-06', '06-09', '09-12', '12-15', '15-18', '18-21', '21-00' ],
            },
	    colors: [ '#798897' ],
        };	
	const optionsDowBars = {
	    ...barOptions,
            xaxis: {
                categories: [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ],
            },
	    colors: [ '#798897' ],
        };	
	
	let donutOptions = {
	    legend: { show: false },
	    ...chartStates,
	    chart: {
	    	animations: {
		    enabled: false
		},
	    },
	    tooltip: {
		enabled: true,
		y: {
		    formatter: basicFormatterAbsolute,
		},		    
	    }
	};
	let genderOptions = {
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.updateFilter('gender', config.selectedDataPoints[0]);
			this.updateFilter('gender', config.dataPointIndex);
		    }
		},
		animations: {
		    enabled: false
		},
	    }
	};
	let genderDonutOptions = {
	    ...donutOptions,
	    ...genderOptions,
	    labels: this.genderLabels,
	};
	let genderBarOptions = {
	    ...barOptions,
	    ...genderOptions,
	    xaxis: {
                categories: this.genderLabels,
            },
	};
	let datasetTypeOptions = {
	    ...barOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.updateFilter('dataset_type', config.dataPointIndex);
		    }
		},
		animations: {
		    enabled: false
		},
	    },
	    labels: this.datasetTypeLabels,
	    colors: [ '#86ff6c', '#91ce7d', '#969d89', '#bc5090', '#f97158', '#fe8c3a', '#ffa600' ]
	};
	let sentimentOptions = {
	    ...donutOptions,
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			this.updateFilter('sentiment', config.dataPointIndex);
		    }
		},
		animations: {
		    enabled: false
		},
		toolbar: {
		    show: true,
		    tools: {
			download: true,
		    },
		},
	    },
	    colors: this.sentimentColors,
	    labels: this.sentimentLabels,
	};
	let deviceOptions = {
	    chart: {
		events: {
		    dataPointSelection: (event, chart, config) => {
			// this.updateFilter('device', config.selectedDataPoints[0]);
			this.updateFilter('device', config.dataPointIndex);
		    }
		},
		animations: {
		    enabled: false
		},
	    },
	};
	let deviceDonutOptions = {	   
	    ...donutOptions,
	    ...deviceOptions,
	    labels: this.deviceLabels,
	};
	let deviceBarOptions = {	   
	    ...barOptions,
	    ...deviceOptions,
	    xaxis: {
		categories: this.deviceLabels,
	    },
	};

	const selectArticle = (article) => {
	    this.setState({ selectedArticle: article });
	}
	const onContextMenu = (anchor, article) => {
	    this.setState({ anchor: anchor, selectedArticle: article });
	}

	const handleClose = () => {
            this.setState({ anchor: null, topicAnchor: null });
	}

	const openLink = () => {
	    window.open(this.state.selectedArticle.link);
	    handleClose();
	}

	const reclassify = () => {
	    this.setState({ reclassify: true });
	    handleClose();
	}

	const editTopic = () => {
	    this.setState({ editTopic: true });
	    handleClose();
	}

	const deleteTopic = (for_this) => {
	    this.props.onUpdateTopic(for_this ? projectId : 0, this.state.selectedTopic.id, null, 1);
	    handleClose();
	}

	const toggleTagged = () => {
	    // let params = {
	    //  	'id': this.state.selectedArticle.id,
	    // 	'is_tagged': this.state.selectedArticle.is_tagged ? 0 : 1,
	    // };
	    
	    // const requestOptions = {
            //     method: 'POST',
            //     credentials: 'include',
            //     headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            //     body: new URLSearchParams(params)
            // };
            // fetch("https://api.sometrik.com/project/tag_message", requestOptions)
            //     .then(res => res.json())
            //     .then(
            //         (result) => {
            //             if (result.data.status == "1") {
            //                 this.props.app.addToast.bind(this.props.app)("ok");
            //             } else {
            //                 this.props.app.addToast.bind(this.props.app)('Failed to tag message', 'error');
            //             }
	    // 		this.reloadAll();
            //         },
            //         (error) => {
            //             this.props.app.addToast.bind(this.props.app)('Failed to contact server', 'error');
            //         }
            //     )
	    
	    // handleClose();
	}

	const reclassifyArticle = (id, emotions, sentiment) => {
	    let params = {
	     	'id': id,
		'emotions': emotions.join(','),
		'sentiment': sentiment.join(','),
	    };
	    
	    const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                body: new URLSearchParams(params)
            };
            fetch("https://api.sometrik.com/train/add", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.data.status == "1") {
                            this.props.app.addToast.bind(this.props.app)("Ok");
                        } else {
                            this.props.app.addToast.bind(this.props.app)('Failed to reclassify', 'error');
                        }
                    },
                    (error) => {
                        this.props.app.addToast.bind(this.props.app)('Failed to contact server', 'error');
                    }
                )
	}

	const onTopicClick = (id) => {
	    this.updateFilter('topic', id);
	};
	const seriesTopTopicData = [ {
	    name: "Osuus",
	    data: topTopicData.map( t => t.activity ),
	} ];

	const seriesEmotionalTopicData = [ {
	    name: "Osuus",
	    data: emotionalTopicData.map( t => t.emotion_amount ),
	} ];

	const seriesTrendingTopicData = [ {
	    name: "Osuus",
	    data: trendingTopicData.map( t => t.trending_amount ),
	} ];
	
	const onTopicMenu = (anchor, topic) => {
	    this.setState({ topicAnchor: anchor, selectedTopic: topic });
	}

	// const checkData = (name, series) => {
	//     if (series == null) {
	// 	console.log(name + ": series is null");
	//     } else {
	// 	for (let i = 0; i < series.length; i++) {
	// 	    if (series[i].data == null) {
	// 		console.log(name + ": series[" + i + "].data is null");
	// 	    }
	// 	}
	//     }
	// }
	// checkData("seriesTopTopicData", seriesTopTopicData);
	// checkData("seriesTopEmotionData", seriesTopEmotionData);
	// checkData("seriesHourBars", seriesHourBars);
	// checkData("seriesDowBars", seriesDowBars);
	// checkData("topFearData", topFearData);
	// checkData("genderData", genderData);
	// checkData("deviceData", deviceData);

	
	if (error) {
	    return <div>Error</div>;
	} else {
	    return (
		<>
		    <Route exact path={base_url + "/tags"}>
			<ShowMoreDialog dialogTitle="More hashtags" data={ hashtagData.map( h => ( [ null, h[0], h[1] ] ) ) } open={true} onClose={() => rewindHistory(base_url)} />
		    </Route>
		    
		    <Route exact path={base_url + "/platforms"}>
                        <ShowMoreDialog dialogTitle="More platforms" data={ platformData.map( ds => (
					    [
						null,
						getDatasetName(ds.id),
						basicFormatter(ds.activity),
						ds.id,
						() => this.updateFilter('dataset', ds.id)
					    ]
					))}
					open={true} onClose={() => rewindHistory(base_url)} />
                    </Route>

		    <Route exact path={base_url + "/countries"}>
                        <ShowMoreDialog dialogTitle="More countries" data={ topCountriesData.map( ds => (
                                            [
						null,
                                                getCountryName(ds.id),
                                                basicFormatter(ds.activity),
                                                ds.id,
                                                () => this.updateFilter('country', ds.id)
                                            ]
                                        ))}
					open={true} onClose={() => rewindHistory(base_url)} />
                    </Route>

		    <Route exact path={base_url + "/provinces"}>
                        <ShowMoreDialog dialogTitle="More provinces" data={ topProvincesData.map( ds => (
                                            [
						null,
                                                getProvinceName(ds.id),
                                                basicFormatter(ds.activity),
                                                ds.id,
                                                () => this.updateFilter('province', ds.id)
                                            ]
                                        ))}
					open={true} onClose={() => rewindHistory(base_url)} />
                    </Route>

		    <Route exact path={base_url + "/topics"}>
                        <ShowMoreDialog dialogTitle="Topics" data={ topTopicData.map( t => (
					    [
						null,
						(active_categories && active_categories[t.id]) || ('#' + t.id),
						basicFormatterDigits(t.activity),
						t.id,
						() => this.updateFilter('topic', t.id)
					    ]
					))}
					open={true} onClose={() => rewindHistory(base_url)} />
                    </Route>

		    <Route exact path={base_url + "/significant"}>
                        <ShowMoreDialog dialogTitle="Significant Properties" columns={2} data={ this.state.significants && this.state.significants.all.map( t => (
					    [						
						t.type,
						filterNames[t.key] || t.key,
						(t.value > 0 ? '+' : '') + t.value.toFixed(2),
						t.key,
						// () => this.updateFilter('topic', t.id)
					    ]
					))}
					open={true} onClose={() => rewindHistory(base_url)} />
                    </Route>

		    <Route exact path={base_url + "/messages"}>
                        <MessageDialog user={user} projectId={projectId} project={project} topics={active_categories} emotionLabels={this.emotionLabels} filter={filter} open={true} onClose={() => rewindHistory(base_url)} selectArticle={selectArticle} reclassify={reclassify} toggleTagged={toggleTagged} datasets={staticData.datasets} />
                    </Route>
		    
		    <Grid container spacing={3}>
			<Grid item xs={12} md={show_index && false? 8 : null} lg={show_index && false ? 8 : null}>
			    <Card className={classes.standardChart}>
				<CardContent>
                                    <CardTitle>Activity</CardTitle>
				    <BrushChart key={filter.tv} isRelative={isRelative} activityData={activityData} brushData={brushData} onTimeSelection={handleTimeSelection} onClearFixed={clearFixedTimeSelection} fixedTimeSelection={filter.t} colors={brushChartColors} />
				    { !is_static ? <TimeSelector n={this.props.numDays} fixedTimeSelection={filter.t} onTimeSelect={onSelectFixedTime} /> : null }
				</CardContent>
			    </Card>
			</Grid>

			{ show_topics ?
			  <Grid item xs={12} md={4} lg={4}>			
			      <Card className={classes.standardChart}>
				  <CardContent>
				      <CardTitle>Topics</CardTitle>
				      <Table className={classes.statisticsTable} size="small">
					  <TableBody>
					      { topTopicData.slice(0, 5).map( t => (
						  <TableRow key={t.id}>
						      <TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.updateFilter('topic', t.id)}>{ (active_categories && active_categories[t.id]) || '#' + t.id}</Link></TableCell>
						      <TableCell align="right">{basicFormatterDigits(t.activity)} <IconButton size="small" onClick={ (e) => { onTopicMenu(e.currentTarget, t) } } ><MoreVertIcon /></IconButton></TableCell>
						  </TableRow>
					      ) ) }					    
					  </TableBody>
				      </Table>
				  </CardContent>
				  { topTopicData.length > 6 ? <CardActions>
								  <Button onClick={() => moveToPage(base_url + "/topics")}>Show {topTopicData.length - 6 } more</Button>
							      </CardActions> : null }
			      </Card>
			  </Grid>
			  : null }
			
			<Grid item xs={12} md={4} lg={4}>			    
		    	    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>Source Type</CardTitle>
				    <Chart style={{marginTop:'-1em'}} key="sourceType" options={datasetTypeOptions} series={datasetTypeData} type="bar" />
				</CardContent>
			    </Card>
			</Grid>

			{ show_index ? <IndexValueCard index={activeData && activeData.total_activityindex_value} baseIndex={unfilteredData && unfilteredData.index_value} n={activeData && activeData.total_activity} decimals={2} /> : null }

			{ false &&
			  <Grid item xs={12} md={4} lg={4}>
			      <Card className={classes.standardChart}>
				  <CardContent>
				      <CardTitle>Sentiment</CardTitle>
				      <Chart style={{marginTop:'-1em'}} options={sentimentOptions} series={sentimentData} type="donut" />
				  </CardContent>
			      </Card>
			  </Grid>
			}

			{ show_topics ?
			  <>
			      <Grid item xs={12} md={4} lg={4}>
				  <CardTitle>Trending Topics</CardTitle>
				  <Card className={classes.standardChart}>
				      <TopicDeck project={project} data={trendingTopicData} topics={active_categories} sentimentLabels={this.sentimentLabels} sentimentColors={this.sentimentColors} emotionLabels={this.emotionLabels} onUpdateFilter={onUpdateFilter} messages={messages && messages.topic_messages} t={activeData && activeData.timeseries && activeData.timeseries.t} datasets={staticData.datasets} onClick={onTopicClick} onContextMenu={onTopicMenu} />
				  </Card>
			      </Grid>

			      <Grid item xs={12} md={4} lg={4}>
				  <CardTitle>Emotionally Intensive Topics</CardTitle>
				  <Card className={classes.standardChart}>
				      <TopicDeck project={project} data={emotionalTopicData} topics={active_categories} sentimentLabels={this.sentimentLabels} sentimentColors={this.sentimentColors} emotionLabels={this.emotionLabels} onUpdateFilter={onUpdateFilter} messages={messages && messages.topic_messages} t={activeData && activeData.timeseries && activeData.timeseries.t} datasets={staticData.datasets} onClick={onTopicClick} onContextMenu={onTopicMenu} />
				  </Card>
			      </Grid>
			      
			      <Grid item xs={12} md={4} lg={4}>
				  <CardTitle>Popular Topics</CardTitle>
				  <Card className={classes.standardChart}>
				      <TopicDeck project={project} data={topTopicData} topics={active_categories} sentimentLabels={this.sentimentLabels} sentimentColors={this.sentimentColors} emotionLabels={this.emotionLabels} onUpdateFilter={onUpdateFilter} messages={messages && messages.topic_messages} t={activeData && activeData.timeseries && activeData.timeseries.t} datasets={staticData.datasets} onClick={onTopicClick} onContextMenu={onTopicMenu} />
				  </Card>
			      </Grid>
			  </>
			  : null }

			{ show_emotions ?
			  <Grid item xs={12} md={8} lg={8}>
			      <Card className={classes.standardChart}>
				  <CardContent>
				      <CardTitle>Emotions</CardTitle>
				      <Chart
					  options={optionsEmotionBars}
					  series={emotionData}
					  type="bar"
					  height={240}
				      />
				  </CardContent>
			      </Card>
			  </Grid>
			  : null }

			<Grid item xs={12} md={4} lg={4}>			
			    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>{texts.platform_caption}</CardTitle>
				    <Table className={classes.statisticsTable} size="small">
					<TableBody>
					    { platformData.slice(0, 6).map( ds => (
						<TableRow key={ds.id}>
						    <TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.updateFilter('dataset', ds.id)}>{getDatasetName(ds.id)}</Link></TableCell>
						    <TableCell align="right">{basicFormatter(ds.activity)}</TableCell>
						</TableRow>
					    ) ) }
					</TableBody>
				    </Table>
				</CardContent>
				{ totalPlatforms > 6 ? <CardActions>
							    <Button onClick={() => moveToPage(base_url + "/platforms")}>Show {totalPlatforms - 6 } more</Button>
							</CardActions> : null }
			    </Card>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>			
			    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>Own User Activity</CardTitle>
				    <Table className={classes.statisticsTable} size="small">
					<TableBody>
					    <TableRow key={0}>
						<TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.updateFilter('own_user', 0)}>Other users</Link></TableCell>
						<TableCell align="right">{ownUserData[0]}</TableCell>
					    </TableRow>
					    <TableRow key={1}>
						<TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.updateFilter('own_user', 1)}>Own users</Link></TableCell>
						<TableCell align="right">{ownUserData[1]}</TableCell>
					    </TableRow>
					</TableBody>
				    </Table>
				</CardContent>
			    </Card>
			</Grid>

			{ show_topics || show_emotions ? 
			  <Grid item xs={12} md={8} lg={8}>
			      <Card className={classes.standardChart}>
				  <CardContent>
				      <CardTitle>Significant Variables</CardTitle>
				      { !is_filtered ? <div>Rajaamalla aineistoa näet rajatun aineiston tärkeimmät ominaisuudet</div> :
					(this.state.significants && this.state.significants.top.length > 0 ?
					 <Chart
					     options={topFearOptions}
                                             series={this.state.significants.top}
                                             type="bar"
                                             height={220}
					 />				      				      
					 :
					 <Box className={classes.statusContainer} display="flex" alignItems="center">                                               
					     <div className={classes.statusInnerContainer}>                                                                         
                				 <CircularProgress />
					     </div>                                                                                                                 
					 </Box>		  		     
					)
					}
				
				  </CardContent>
				  { is_filtered && this.state.significants && this.state.significants.all.length > 5 ? <CardActions>
                                                             <Button onClick={() => moveToPage(base_url + "/significant")}>Show all</Button>
							 </CardActions> : null }
			      </Card>
			  </Grid>
			  : null }

			<Grid item xs={12} md={4} lg={4}>			
			    <Card className={classes.standardChart}>
				<CardContent>
				    <CardTitle>{texts.province_caption}</CardTitle>
				    <Table className={classes.statisticsTable} size="small">
					<TableBody>
					    { topProvincesData.slice(0, 6).map( c => (
						<TableRow key={c.id}>
						    <TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.updateFilter('province', c.id)}>{getProvinceName((c.id))}</Link></TableCell>
						    <TableCell align="right">{basicFormatter(c.activity)}</TableCell>
						</TableRow>
					    ) ) }					    
					</TableBody>
				    </Table>
				</CardContent>
				{ topProvincesData.length > 6 ? (
				    <CardActions>
					<Button onClick={() => moveToPage(base_url + "/provinces")}>Show {topProvincesData.length - 6} more</Button>
				    </CardActions>
				) : null }				  
			    </Card>
			</Grid>

						{ showTags ? 
			  <Grid item xs={12} md={4} lg={4}>			
			      <Card className={classes.standardChart}>
				  <CardContent>
				      <CardTitle>Tags</CardTitle>
				      <Table className={classes.statisticsTable} size="small">
					  <TableBody>
					      { hashtagData.slice(0, 6).map( item => (
						  <TableRow key={item[0]}>
						      <TableCell component="th" scope="row" className={classes.tableHeader}>{item[0]}</TableCell>
						      <TableCell align="right">{item[1]}</TableCell>
						  </TableRow>
					      ) ) }
					  </TableBody>
				      </Table>
				  </CardContent>
				  { totalHashtags > 6 ? (
				      <CardActions>
					  <Button onClick={() => moveToPage(base_url + "/tags")}>Show {totalHashtags - 6 } more</Button>
				      </CardActions>
				  ) : null }				  
			      </Card>
			  </Grid>
			  : null }

			
			<Grid item xs={12}>
                            <Card className={classes.chart}>
                                <CardContent>
                                    <CardTitle>Example Content</CardTitle>
				    <Table className={classes.statisticsTable} size="small">
					<TableBody>
					    { (messages ? messages.data : []).map( item => (
						<TableRow key={item.key}>
						    <TableCell><Message user={user} positive_regex={project && project.positive_regex} negative_regex={project && project.negative_regex} article={item} topics={active_categories} emotionLabels={this.emotionLabels} onContextMenu={onContextMenu} datasets={staticData.datasets} /></TableCell>
						</TableRow>
					    ))}
					</TableBody>
				    </Table>
                                </CardContent>
				{ messages && messages.n > 5 ? <CardActions>
								   <Button onClick={() => moveToPage(base_url + "/messages")}>Show {messages.n - 5 } more</Button>
							       </CardActions> : null }

                            </Card>
                        </Grid>

			{ false ?
			  <>
			      <Grid item xs={12} md={4} lg={4}>
				  <Card className={classes.standardChart}>
				      <CardContent>
					  <CardTitle>Activity By Hour</CardTitle>
					  <Chart
					      options={optionsHourBars}
					      series={seriesHourBars}
					      type="bar"
					      height={240}
					  />
				      </CardContent>
				  </Card>
			      </Grid>

			      <Grid item xs={12} md={4} lg={4}>
				  <Card className={classes.standardChart}>
				      <CardContent>
					  <CardTitle>Activity by Day of Week</CardTitle>
					  <Chart
					      options={optionsDowBars}
					      series={seriesDowBars}
					      type="bar"
					      height={240}
					  />
				      </CardContent>
				  </Card>
			      </Grid>
			      
			      <Grid item xs={12} md={4} lg={4}>			    
		    		  <Card className={classes.standardChart}>
				      <CardContent>
					  <CardTitle>{texts.gender_caption}</CardTitle>
					  <Chart key={isRelative ? 'genderR' : 'gender'} options={isRelative ? genderBarOptions : genderDonutOptions} series={genderData} type={isRelative ? "bar" : "donut"} />
				      </CardContent>
				  </Card>
			      </Grid>
			  </>
			  : null }
			
			{ false ?
			  <Grid item xs={12} md={4} lg={4}>			
			      <Card className={classes.standardChart}>
				  <CardContent>
				      <CardTitle>{texts.major_region_caption}</CardTitle>
				      <Table className={classes.statisticsTable} size="small">
					  <TableBody>
					      { topMajorRegionsData.slice(0, 6).map( c => (
						  <TableRow key={c.id}>
						      <TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.updateFilter('major_region', c.id)}>{this.major_regions[c.id].name}</Link></TableCell>
						      <TableCell align="right">{basicFormatter(c.activity)}</TableCell>
						  </TableRow>
					      ) ) }					    
					  </TableBody>
				      </Table>
				  </CardContent>
			      </Card>
			  </Grid> : null }

			{ show_devices ? <Grid item xs={12} md={4} lg={4}>
		    		      <Card className={classes.standardChart}>
					 <CardContent>
					     <CardTitle>{texts.device_caption}</CardTitle>
					     <Chart key={isRelative ? 'deviceR' : 'device'} options={isRelative ? deviceBarOptions : deviceDonutOptions} series={deviceData} type={isRelative ? "bar" : "donut"} />
					 </CardContent>
				     </Card>
				 </Grid>
			  : null }

			{ show_countries ?
			  <Grid item xs={12} md={4} lg={4}>			
			      <Card className={classes.standardChart}>
				  <CardContent>
				      <CardTitle>Activity by Country</CardTitle>
				      <Table className={classes.statisticsTable} size="small">
					  <TableBody>
					      { topCountriesData.slice(0, 6).map( c => (
						  <TableRow key={c.id}>
						      <TableCell component="th" scope="row" className={classes.tableHeader}><Link onClick={() => this.updateFilter('country', c.id)}>{getCountryName(c.id)}</Link></TableCell>
						      <TableCell align="right">{basicFormatter(c.activity)}</TableCell>
						  </TableRow>
					      ) ) }					    
					  </TableBody>
				      </Table>
				  </CardContent>
				  { totalCountries > 6 ? <CardActions>
                                                             <Button onClick={() => moveToPage(base_url + "/countries")}>Show {totalCountries - 6 } more</Button>
							 </CardActions> : null }
			      </Card>
			  </Grid>
			  : null }
			
			<Grid item xs={12}>
			    <Card className={classes.chart}>
				<ProjectGraph graph={this.state.graph} v={this.state.actualVersion} />
			    </Card>
			</Grid>

			{ showUsers ?
			  <>
			      <Grid item xs={12}>
				  <Card className={classes.chart}>
				      <CardContent>
					  <CardTitle>Active users</CardTitle>
					  <TopUserList showFollowers={showFollowers} users={activeUserData} more={more_active_users} datasets={staticData.datasets} />
				      </CardContent>
				      { activeUserData.length > 5 ?
					<CardActions>
					    <Button size="small" onClick={toggleMoreActiveUsers}>{ more_active_users ? 'Show Less' : 'Show More' }</Button>
					</CardActions>
					: null }
				  </Card>
			      </Grid>

			      <Grid item xs={12}>
				  <Card className={classes.chart}>
				      <CardContent>
					  <CardTitle>Popular users</CardTitle>
					  <TopUserList showFollowers={showFollowers} users={popularUserData} more={more_popular_users} datasets={staticData.datasets} />
				      </CardContent>
				      { popularUserData.length > 5 ?
					<CardActions>
					    <Button size="small" onClick={toggleMorePopularUsers}>{ more_popular_users ? 'Show Less' : 'Show More' }</Button>
					</CardActions>
					: null }
				  </Card>
			      </Grid>
			  </>
			  : null }

			<Grid item xs={12}>
			    <Card className={classes.chart}>
				<MapChart title={texts.map_caption} v={this.state.actualVersion} dataAvailable={isLoaded && staticData.isLoaded} isRelative={isRelative} style={{margin:0,padding:0}} layers={layers} proj={{
					      rotate: [-25.0, -65.0, 0],
					      scale: 3000
					  }}
				/>
			    </Card>
			</Grid>

		    </Grid>
		    
		    <Menu
			anchorEl={this.state.anchor}
			keepMounted
			open={this.state.anchor != null}
			onClose={handleClose}			
		    >
			<MenuItem onClick={ () => openLink() } disabled={this.state.selectedArticle == null || this.state.selectedArticle.link == null}>View</MenuItem>
			{ user.is_admin ? <MenuItem onClick={ () => reclassify() } disabled={this.state.selectedArticle == null || this.state.selectedArticle.rss_post_id != 0}>Reclassify</MenuItem> : null }
			<MenuItem onClick={ () => toggleTagged() } disabled={this.state.selectedArticle == null}>{ !this.state.selectedArticle || !this.state.selectedArticle.is_tagged ? 'Add Tag' : 'Remove Tag' }</MenuItem>
		    </Menu>

		    <Menu
			anchorEl={this.state.topicAnchor}
			keepMounted
			open={this.state.topicAnchor != null}
			onClose={handleClose}			
		    >
			<MenuItem onClick={ () => editTopic() }>Edit for All Projects</MenuItem>
			<MenuItem onClick={ () => deleteTopic(true) }>Delete for This Project</MenuItem>
			<MenuItem onClick={ () => deleteTopic() }>Delete for All Projects</MenuItem>
		    </Menu>

		    <ReclassifyDialog open={this.state.reclassify} article={this.state.selectedArticle} onClose={ () => this.setState({ reclassify: false }) } onReclassify={reclassifyArticle} zIndex="snackbar" />
		    <EditTopicDialog open={this.state.editTopic} topic={this.state.selectedTopic} topics={active_categories} onClose={ () => this.setState({ editTopic: false }) } onUpdateTopic={this.props.onUpdateTopic} zIndex="snackbar" />

		</>
	    );
	}
    }
}

export default withRouter(withStyles(styles)(CubeView));
